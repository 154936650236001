<style lang="scss" scoped>
.ui-left {
  width: 360px;
  position: absolute;
  top: 109px;
  left: 20px;
  z-index: 4;
  img {
    width: 100%;
  }
}
.ui-right {
  width: 360px;
  position: absolute;
  top: 109px;
  right: 20px;
  z-index: 4;
  .right-head {
    width: 100%;
    height: 34px;
  }
  img {
    width: 100%;
  }
  .table-head {
    margin-top: 12px;
    width: 100%;
  }
  .table-body {
    pointer-events: all;
    width: 100%;
    max-height: 816px;
    margin-top: 8px;
    .table-item {
      &:not(:first-child) {
        margin-top: 4px;
      }
      width: 100%;
      height: 37px;
      padding: 8px 16px;
      display: grid;
      align-items: center;
      grid-template-columns: 2fr 2fr 3fr;
      position: relative;
      height: 38px;
      .icon-head {
        display: none;
        position: absolute;
        width: 19px;
        height: 24px;
        top: 9px;
        left: -8px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .select-item {
      background: linear-gradient(90deg, #5257d0 0%, #393b71 100%);
      .icon-head {
        display: block;
      }
    }
    .script {
      background-color: #0000001f;
    }
  }
}
.item-1 {
  box-sizing: content-box;
  width: 36px;
  height: 21px;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 12px;
  color: #fff;
}
.status0 {
  border: 1px solid var(--unnamed, #ffa928);
  background: rgba(255, 169, 40, 0.25);
  box-shadow: 0px 0px 8px 0px #ffa928 inset;
}
.status1 {
  border: 1px solid var(--unnamed, #06f);
  background: rgba(77, 143, 255, 0.2);
  box-shadow: 0px 0px 8px 0px #4d8fff inset;
}
.status2 {
  border: 1px solid #76ffe9;
  background: rgba(43, 178, 189, 0.5);
  box-shadow: 0px 0px 8px 0px #22ffd7 inset;
}
.item-2 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
.dialog {
  position: absolute;
  right: 394px;
  width: 280px;
  top: 112px;
  z-index: 100;
  .dialog-top {
    width: 100%;
    height: 28px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .dialog-body {
    background-color: #1b1d35cc;
    width: 100%;
    padding: 0 8px;
    padding-top: 16px;
    padding-bottom: 24px;
    img {
      width: 100%;
      height: 100%;
    }
    .sub-title {
      width: 100%;
      height: 24px;
    }
    .monitor-canvas {
      width: 100%;
      height: 160px;
      padding-left: 8px;
      padding-right: 8px;
      margin-top: 8px;
      position: relative;
      margin-bottom: 16px;
      .name-content {
        width: 248px;
        position: absolute;
        bottom: 0;
        height: 29px;
        left: 8px;
        padding-left: 10px;
        background: linear-gradient(
          90deg,
          #393b71 0%,
          rgba(57, 59, 113, 0.4) 100%
        );
        font-size: 14px;
        color: #ffffff;
        display: flex;
        align-items: center;
      }
    }
    .sb-list {
      width: 248px;
      padding-left: 8px;
      .list-item {
        width: 100%;
        height: 28px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.12);
        margin-top: 8px;
        padding: 4px 8px;
        display: flex;
        .left-text {
          text-align: justify;
          text-align-last: justify;
          width: 56px;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff99;
          margin-right: 16px;
        }
        .right-text {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }
        .right-status {
          width: 40px;
          height: 22px;
          border-radius: 4px;
          border: 1px solid #76ffe9;
          background: rgba(43, 178, 189, 0.5);
          box-shadow: 0px 0px 8px 0px #22ffd7 inset;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #fff;
        }
        .right-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.b1 {
  top: 50px;
  right: 100px;
  // left:10px;
}

.b2 {
  top: 90px;

  right: 100px;
  // left:10px;
}

.b3 {
  top: 130px;
  right: 100px;
  // left:10px;
}
</style>

<template>
  <div class="index">
    <div class="ui-left" v-rc:left>
      <img src="./ui-left.png" alt="" />
      <div class="nb b1" @click="show('normal')"></div>
      <div class="nb b2" @click="show('standby')"></div>
      <div class="nb b3" @click="show('warning')"></div>
    </div>
    <div class="ui-right" v-rc:right>
      <div class="right-head">
        <img src="@/assets/jk-head.png" alt="" />
      </div>
      <div class="table-head">
        <img src="@/assets/table-head.png" alt="" />
      </div>
      <div class="table-body">
        <div
          class="table-item"
          v-for="(item, index) in list"
          :class="{
            script: index % 2 != 0,
            'select-item': selectIndex == index,
          }"
          @click="openDialog(item, index)"
        >
          <div class="icon-head">
            <img src="@/assets/icon-head.png" alt="" />
          </div>
          <div class="item item-1" :class="[classStatus(item)[1]]">
            {{ classStatus(item)[0] }}
          </div>
          <div class="item item-2">{{ item.position }}</div>
          <div class="item item-2">{{ item.time }}</div>
        </div>
      </div>
    </div>
    <div class="dialog" v-if="selectItem">
      <div class="dialog-top">
        <img src="@/assets/dialog-head.png" alt="" />
      </div>
      <div class="dialog-body">
        <div class="sub-title">
          <img src="@/assets/sub-jk.png" alt="" />
        </div>
        <div class="monitor-canvas">
          <img src="@/assets/pic-car.png" alt="" />
          <div class="name-content">地面-1001</div>
        </div>
        <div class="sub-title">
          <img src="@/assets/sub-sb.png" alt="" />
        </div>
        <div class="sb-list">
          <div class="list-item">
            <div class="left-text">设备编码</div>
            <div class="right-text">D9:3C:28:8F:41:53</div>
          </div>
          <div class="list-item">
            <div class="left-text">设备位置</div>
            <div class="right-text">{{ selectItem.position }}</div>
          </div>
          <div class="list-item">
            <div class="left-text">设备状态</div>
            <div class="right-status">正常</div>
          </div>
          <div class="list-item">
            <div class="left-text">负责人</div>
            <div class="right-text">郑盈</div>
          </div>
          <div class="list-item">
            <div class="left-text">告警状态</div>
            <div
              class="item-1 right-center"
              :class="[classStatus(selectItem)[1]]"
            >
              {{ classStatus(selectItem)[0] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/lib/bus";
export default {
  data() {
    return {
      selectIndex: null,
      selectItem: null,
      list: [
        { status: 0, position: "B1层入口", time: "2023/06/17   22:45" },
        { status: 1, position: "地面车位1204", time: "2023/06/16   16:53" },
        { status: 2, position: "B1层入口", time: "2023/06/17   22:45" },
        { status: 2, position: "地面车位1204", time: "2023/06/16   16:53" },
        { status: 2, position: "B1层入口", time: "2023/06/17   22:45" },
        { status: 2, position: "地面车位1204", time: "2023/06/16   16:53" },
        { status: 2, position: "B1层入口", time: "2023/06/17   22:45" },
        { status: 2, position: "地面车位1204", time: "2023/06/16   16:53" },
        { status: 2, position: "B1层入口", time: "2023/06/17   22:45" },
        { status: 2, position: "地面车位1204", time: "2023/06/16   16:53" },
        { status: 2, position: "B1层入口", time: "2023/06/17   22:45" },
        { status: 2, position: "地面车位1204", time: "2023/06/16   16:53" },
        { status: 2, position: "B1层入口", time: "2023/06/17   22:45" },
        { status: 2, position: "地面车位1204", time: "2023/06/16   16:53" },
        { status: 2, position: "B1层入口", time: "2023/06/17   22:45" },
        { status: 2, position: "地面车位1204", time: "2023/06/16   16:53" },
        { status: 2, position: "B1层入口", time: "2023/06/17   22:45" },
      ],
    };
  },
  computed: {},
  mounted(){
    bus.$emit( 'set-sprite-display', 'changdijiankong', 'all', true)
    bus.$emit( 'set-sprite-display', 'daozhajiankong', 'all', true)
  },
  methods: {
    show(name) {
             bus.$emit("sceneRunning1",true)
      bus.$emit("set-sprite-display", "changdijiankong", name, true);
      bus.$emit("set-sprite-display", "daozhajiankong", name, true);
    },
    showtotat() {
      bus.$emit("totat");
    },
    openDialog(item, index) {
      if (this.selectIndex == index) {
        this.selectIndex = null;
        this.selectItem = null;
        return;
      }
      this.selectIndex = index;
      this.selectItem = item;
    },
    classStatus({ status }) {
      if (status == 0) {
        return ["未上报", "status0"];
      } else if (status == 1) {
        return ["已上报", "status1"];
      } else {
        return ["已处理", "status2"];
      }
    },
  },
};
</script>
